.crossed-out {
  text-decoration: line-through;
  opacity: 50%;
}

.button { 
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
}